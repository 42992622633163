import type { Json } from "@redotech/json/json";
import { CountryCode } from "@redotech/locale/countries";
import type { Equal } from "@redotech/util/equal";
import {
  arrayEqual,
  booleanEqual,
  objectEqual,
  optionalEqual,
  stringEqual,
} from "@redotech/util/equal";
import { NumberRange } from "@redotech/util/type";
import type { Decimal128Extended } from "bson";
import type { Stripe } from "stripe";
import { z } from "zod";
import type { AdLinkType } from "./ad-link";
import { SocialLoginOption } from "./auth/social-auth";
import {
  BillingPeriod,
  CheckoutOptimizationBillingPeriod,
} from "./billing/service-invoice";
import type { BrandKit } from "./brand-kit";
import { FulfillmentCutoffSchedule } from "./checkouts/fulfillment-cutoff";
import { zExt } from "./common/zod-util";
import type { ResponseLength } from "./concierge-conversation/concierge-conversation-message-definition";
import type { ConversationTag } from "./conversation";
import type { Coverage, PaymentModel } from "./coverage";
import type { AdBlock } from "./customer-accounts/ad-block";
import { CustomerWidgetChip } from "./customer-widget/customer-widget";
import type { DiscountSettings, DiscountValueType } from "./discount";
import {
  EmailDomainSettings,
  isVerifiedEmailDomain,
} from "./email/domain-verification";
import { ExtendedWarrantySettings } from "./extended-warranty";
import { IFaqTheme } from "./faq/faq-body-schema";
import { CustomsDeclaration } from "./fulfillments/customs-declaration";
import type { LanguageResourceOverride } from "./localization/resource";
import { ItemOrderBy, PageFormat, PageOrientation } from "./outbound-labels";
import { ServiceLevelPriority } from "./outbound-labels/auto-merge";
import {
  AESExemptionType,
  ContentsType,
  CustomsDeclarationType,
  NonDeliveryOption,
  TaxIdUsage,
  USPSExemptionCode,
} from "./outbound-labels/customs";
import { FulfillmentGroupMergeMatcherKind } from "./outbound-labels/fulfillment-group-merge-matcher";
import { LearnedPresetKind } from "./outbound-labels/learned-preset";
import type { PaymentMethod } from "./payout";
import { PrinterOptions } from "./printnode/printer";
import { CompensationMethod, ReturnTypeEnum } from "./return";
import type { Bundle, BundleRules } from "./return-flow";
import type { PriceOption, SkuOption } from "./return-flow/condition";
import type { TriggerType } from "./return-flow/trigger";
import { ReturnStatus, ReturnStatusZod } from "./return-status";
import { ShopifyIntegration } from "./shopify";
import {
  ShopifySubscription,
  getUsagePlanPricing,
} from "./shopify-subscription/shopify-subscription";
import { SlideInPanelAnchorLocation } from "./slide-in-panel/slide-in-panel-anchor-location";
import { TextMessagingBilling } from "./support/billing/text-message-billing";
import { TeamVoiceConfiguration } from "./support/voice/voice-types";
import { GetUserZodSchema, type GetUser } from "./user";
import { WarrantiesSettings } from "./warranties";
import {
  ClickStandardCheckoutButton,
  InfoModal,
  TermsAndConditions,
} from "./widget";
import { WidgetConfig } from "./widget-schema";

export interface StorefrontConfig {
  hostname: string;
  storefrontAccessToken: string;
}

export enum BARCODE_HEADER_FOOTER_OPTIONS {
  UPC = "upc",
  PRODUCT_VARIANT_NAME = "product-variant-name",
  PRODUCT_OVER_SKU = "product-over-sku",
  SKU_AND_WAREHOUSE = "sku-and-warehouse",
  NONE = "none",
}

export enum LocationCondition {
  PRODUCT_TAGS = "product_tags",
  DAMAGED = "damaged",
  NAME = "name",
  MULTIPLE_CHOICE = "multiple_choice",
  RETURN_REASON = "return_reason",
  COLLECTIONS = "collections",
  ORDER_TAGS = "order_tags",
  CUSTOMER_TAGS = "customer_tags",
  DISCOUNTS = "discounts",
  PRICE = "price",
  SKU = "sku",
  SALES_CHANNEL = "sales_channel",
  CUSTOMER_COUNTRY = "customer_country",
  CUSTOMER_ADDRESS = "customer_address",
  FINAL_SALE_RETURNS = "final_sale_returns",
  IS_REPAIR = "is_repair",
}

export enum ShippingInsurance {
  DISABLED = "disabled",
  ENABLED = "enabled",
}

export enum ExchangesSelection {
  RETURN_APP = "return_app",
  STORE_WEBSITE = "store_website",
}

export interface GetTeam {
  roles: string[];
  _id: string;
  email: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  name: string;
  updatedAt: string;
  images: string[];
  updatePermissionsLink?: string;
  platform: "shopify" | "commerce-cloud" | "commentsold";
  shopify?: ShopifyIntegration;
  team: Team;
}

export enum AttachmentStrategy {
  CHECK_OUT = "check-out",
  CHECKBOX = "checkbox",
  DOUBLE_CHECK_OUT = "double-check-out",
  DOUBLE_TOGGLE = "double-toggle",
  DROP_DOWN = "drop-down",
  SINGLE_TOGGLE = "single-toggle",
  CART_CARD = "cart-card",
  CHECKOUT_BUTTON_PRODUCT = "checkout-button-product",
  CHECKOUT_BUTTON_SHIPPING = "checkout-button-shipping",
  SHIPPING = "shipping",
  TOGGLE_CUSTOM_CHECKOUT_FEE = "toggle-custom-checkout-fee",
}

export const bigcommerceAttachmentStrategies: AttachmentStrategy[] = [
  AttachmentStrategy.SINGLE_TOGGLE,
  AttachmentStrategy.TOGGLE_CUSTOM_CHECKOUT_FEE,
];

export const attachmentStrategyToLabel: Record<AttachmentStrategy, string> = {
  [AttachmentStrategy.CHECKBOX]: "PDP checkbox",
  [AttachmentStrategy.SINGLE_TOGGLE]: "Cart single toggle",
  [AttachmentStrategy.DOUBLE_TOGGLE]: "Cart double toggle",
  [AttachmentStrategy.CHECK_OUT]: "Checkout single checkbox",
  [AttachmentStrategy.DOUBLE_CHECK_OUT]: "Checkout double checkbox",
  [AttachmentStrategy.CART_CARD]: "Cart card",
  [AttachmentStrategy.DROP_DOWN]: "Cart drop-down",
  [AttachmentStrategy.CHECKOUT_BUTTON_PRODUCT]:
    "Cart checkout buttons: product",
  [AttachmentStrategy.CHECKOUT_BUTTON_SHIPPING]:
    "Cart checkout buttons: shipping",
  [AttachmentStrategy.SHIPPING]: "Redo in shipping",
  [AttachmentStrategy.TOGGLE_CUSTOM_CHECKOUT_FEE]:
    "Toggle custom checkout fee (BigCommerce only)",
};

/** If we convert someone's attachment strategy, use shipping checkout buttons if they were doing the shipping attachment strategy. */
export const attachmentStrategyToCheckoutButtonVersion: Record<
  AttachmentStrategy,
  AttachmentStrategy
> = {
  [AttachmentStrategy.CHECKOUT_BUTTON_SHIPPING]:
    AttachmentStrategy.CHECKOUT_BUTTON_SHIPPING,
  [AttachmentStrategy.CHECKOUT_BUTTON_PRODUCT]:
    AttachmentStrategy.CHECKOUT_BUTTON_PRODUCT,
  [AttachmentStrategy.CART_CARD]: AttachmentStrategy.CHECKOUT_BUTTON_PRODUCT,
  [AttachmentStrategy.DOUBLE_TOGGLE]:
    AttachmentStrategy.CHECKOUT_BUTTON_PRODUCT,
  [AttachmentStrategy.SINGLE_TOGGLE]:
    AttachmentStrategy.CHECKOUT_BUTTON_PRODUCT,
  [AttachmentStrategy.CHECK_OUT]: AttachmentStrategy.CHECKOUT_BUTTON_PRODUCT,
  [AttachmentStrategy.DOUBLE_CHECK_OUT]:
    AttachmentStrategy.CHECKOUT_BUTTON_PRODUCT,
  [AttachmentStrategy.CHECKBOX]: AttachmentStrategy.CHECKOUT_BUTTON_PRODUCT,
  [AttachmentStrategy.DROP_DOWN]: AttachmentStrategy.CHECKOUT_BUTTON_PRODUCT,
  [AttachmentStrategy.SHIPPING]: AttachmentStrategy.CHECKOUT_BUTTON_SHIPPING,
  [AttachmentStrategy.TOGGLE_CUSTOM_CHECKOUT_FEE]:
    AttachmentStrategy.CHECKOUT_BUTTON_SHIPPING,
};

export const isAttachmentStrategyUsingCheckoutButtons: Record<
  AttachmentStrategy,
  boolean
> = {
  [AttachmentStrategy.CHECKOUT_BUTTON_SHIPPING]: true,
  [AttachmentStrategy.CHECKOUT_BUTTON_PRODUCT]: true,
  [AttachmentStrategy.CART_CARD]: false,
  [AttachmentStrategy.DOUBLE_TOGGLE]: false,
  [AttachmentStrategy.SINGLE_TOGGLE]: false,
  [AttachmentStrategy.CHECK_OUT]: false,
  [AttachmentStrategy.DOUBLE_CHECK_OUT]: false,
  [AttachmentStrategy.CHECKBOX]: false,
  [AttachmentStrategy.DROP_DOWN]: false,
  [AttachmentStrategy.SHIPPING]: false,
  [AttachmentStrategy.TOGGLE_CUSTOM_CHECKOUT_FEE]: false,
};

export const isAttachmentStrategyUsingCartToggles: Record<
  AttachmentStrategy,
  boolean
> = {
  [AttachmentStrategy.CHECKOUT_BUTTON_PRODUCT]: true,
  [AttachmentStrategy.CHECKOUT_BUTTON_SHIPPING]: true,
  [AttachmentStrategy.CART_CARD]: true,
  [AttachmentStrategy.DOUBLE_TOGGLE]: true,
  [AttachmentStrategy.SINGLE_TOGGLE]: true,
  [AttachmentStrategy.CHECK_OUT]: false,
  [AttachmentStrategy.DOUBLE_CHECK_OUT]: false,
  [AttachmentStrategy.CHECKBOX]: false,
  [AttachmentStrategy.DROP_DOWN]: false,
  [AttachmentStrategy.SHIPPING]: false,
  [AttachmentStrategy.TOGGLE_CUSTOM_CHECKOUT_FEE]: true,
};

export const isAttachmentStrategyCheckoutExtensionEnabled: Record<
  AttachmentStrategy,
  boolean
> = {
  [AttachmentStrategy.CHECK_OUT]: true,
  [AttachmentStrategy.DOUBLE_CHECK_OUT]: true,
  [AttachmentStrategy.CHECKOUT_BUTTON_SHIPPING]: true,
  [AttachmentStrategy.SHIPPING]: true,
  [AttachmentStrategy.CHECKBOX]: false,
  [AttachmentStrategy.SINGLE_TOGGLE]: false,
  [AttachmentStrategy.DOUBLE_TOGGLE]: false,
  [AttachmentStrategy.CART_CARD]: false,
  [AttachmentStrategy.CHECKOUT_BUTTON_PRODUCT]: false,
  [AttachmentStrategy.DROP_DOWN]: false,
  [AttachmentStrategy.TOGGLE_CUSTOM_CHECKOUT_FEE]: false,
};

export type textSizes =
  | ""
  | "extraSmall"
  | "small"
  | "medium"
  | "large"
  | "extraLarge";

export type Icons = "gift" | "shield" | "lock";

export type DividerLinesType = "none" | "above" | "below" | "above and below";

export type PackagePickupVariant = {
  variantId: string;
  percentage: number;
  // optional text to override the default text
  mainText?: string;
  subText?: string;
  pickupButtonText?: string;
  shippingButtonText?: string;
  returnInStoreText?: string;
};

export interface CheckoutUICustomizationSettings {
  titleTextSize: textSizes;
  subtextSize: textSizes;
  dividerLines: DividerLinesType;
  titleOnly: boolean;
}

export type LabelExpirationStrategy = "return" | "order" | "delivery";

export const upsellCardDefaultText = "You might also like";

export enum UpsellProductSource {
  RECOMMENDATIONS = "recommendations",
  COLLECTION = "collection",
  METAFIELD = "metafield",
  TAG = "tag",
}

export interface OneClickUpsellBilling {
  billingEnabled?: boolean;
  freeUntilDate?: string;
  freeUpsellsShownPerMonth?: number;
  pricePerUpsellShownInCents?: number;
}

export interface PriceBracket {
  value: number;
  pricePoint: number;
}
export interface PricingRuleSet {
  type: "fixed" | "percentage";
  countries: string[];
  priceBrackets: PriceBracket[];
}

export enum DynamicReturnTypes {
  ITEM_COUNT = "item-count",
  CART_VALUE = "cart-value",
}

export enum CustomerPortalVersion {
  V3_0 = "v3.0",
  V3_5 = "v3.5",
  V4_0 = "v4.0",
}

export const doesCustomerPortalSupportBulkReturns: Record<
  CustomerPortalVersion,
  boolean
> = {
  [CustomerPortalVersion.V3_0]: false,
  [CustomerPortalVersion.V3_5]: true,
  [CustomerPortalVersion.V4_0]: true,
};

export enum InfoModalVersion {
  V1 = "v1",
  V2 = "v2",
}

export interface DynamicReturnsPricingItemCount {
  type: DynamicReturnTypes.ITEM_COUNT;
  pricePerAdditonalItem: number;
  maxPrice: number;
}

export interface DynamicReturnsPricingCartValue {
  type: DynamicReturnTypes.CART_VALUE;
  maxPrice: number;
  pricingRuleSet: PricingRuleSet;
}

export type DynamicReturnPricing =
  | DynamicReturnsPricingCartValue
  | DynamicReturnsPricingItemCount;
interface ProcessingAutomation {
  exchanges: ReturnStatus;
  refunds: ReturnStatus;
  store_credit: ReturnStatus;
  exchanges_delay: number;
  refunds_delay: number;
  store_credit_delay: number;
}

export interface StripeData {
  customer_id: string;
  card?: Stripe.Card | null;
  paymentMethod?: PaymentMethod;
  secondaryPaymentMethod?: PaymentMethod;
}

export enum RedoItemFulfillment {
  NONE = "none",
  PARTIAL = "partial",
  IMMEDIATE = "immediate",
}

export type CustomsSettings = {
  defaultContentsType?: ContentsType;
  defaultNonDeliveryOption?: NonDeliveryOption;
  defaultCustomsDeclarationType?: CustomsDeclarationType;
  customsSigner?: string;
  aesExemptionType?: AESExemptionType | null;
  taxIdUsage?: TaxIdUsage | null;
  uspsExemptionCode?: USPSExemptionCode | null;
  predefinedCustomsDeclarations?: CustomsDeclaration[] | null;
  minHSClassifyConfidenceScore?: NumberRange<0, 1> | null;
};

export const ProcessingAutomationZodSchema = z.object({
  exchanges: ReturnStatusZod,
  refunds: ReturnStatusZod,
  store_credit: ReturnStatusZod,
  exchanges_delay: z.number(),
  refunds_delay: z.number(),
  store_credit_delay: z.number(),
});

export const AutomationZodSchema = z.object({
  enable: z.boolean(),
  returnProcessing: ProcessingAutomationZodSchema,
  claimProcessing: ProcessingAutomationZodSchema,
  fulfill_redo_item: z.enum(["none", "partial", "immediate"]),
  returnOrderTags: z.object({ onRefund: z.array(z.string()) }),
});
export interface Automation {
  enable: boolean;
  returnProcessing: ProcessingAutomation;
  claimProcessing: ProcessingAutomation;
  fulfill_redo_item: RedoItemFulfillment;
  returnOrderTags: { onRefund: string[] };
}

export const AddressZodSchema = z.object({
  name: z.string(),
  street1: z.string(),
  street2: z.string().nullish(),
  city: z.string(),
  zip: z.string(),
  state: z.string().nullish(),
  country: z.string(),
  country_name: z.string().nullish(),
  phone: z.string().nullish(),
  createdAt: z.date().nullish(),
  updatedAt: z.date().nullish(),
});
export interface Address {
  name: string;
  street1: string;
  street2?: string;
  city: string;
  zip: string;
  state?: string;
  country: string;
  country_name?: string;
  phone?: string;
  createdAt?: string;
  updatedAt?: string;
  externalLocationId?: string;
  _id?: any;
}

export const PortalZodSchema = z.object({
  _id: zExt.objectId(),
  font_family: z.string(),
  title_font_family: z.string(),
  body_font_family: z.string(),
  shippingFeeText: z.string().nullish(),
  custom_css: z.string(),
  font_weight: z.union([
    z.literal(100),
    z.literal(300),
    z.literal(400),
    z.literal(500),
    z.literal(700),
    z.literal(900),
  ]), //https://github.com/colinhacks/zod/issues/2686
  button_color: z.string(),
  accent_color: z.string(),
  accent_background_color: z.string(),
  home_text_color: z.string(),
  enable_alert: z.boolean(),
  notification: z.string(),
  returnButtonText: z.string(),
  claimButtonText: z.string(),
  notExchangeButtonText: z.string().nullish(),
  exchangeButtonText: z.string().nullish(),
  standard_exchange_text: z.string().nullish(),
  hideDiscountCodes: z.boolean(),
  hideSuggestedProducts: z.boolean(),
  instant_exchange_text: z.string(),
  custom_payment_placeholder_color: z.string().nullish(),
  privacy_link: z.string(),
  background_url: z.string().nullable(),
  favicon_url: z.string().nullable(),
  logo_url: z.string().nullable(),
  colors: z.array(z.string()),
  injection_text_color: z.string(),
  header: z.string().nullish(),
  footer: z.string().nullish(),
  green_return_text: z.string().nullish(),
  custom_confirmation_text: z.string().nullish(),
  green_return_confirmation_text: z.string().nullish(),
  green_return_confirmation_description: z.string().nullish(),
  exchangeOptionText: z.string().nullish(),
  storeCreditOptionText: z.string().nullish(),
  refundOptionText: z.string().nullish(),
  claimReviewHeader: z.string().nullish(),
  claimShippingLineItemText: z.string().nullish(),
  domain: z.string().nullish(),
  pathPrefix: z.string(),
  cantFindOrderText: z.string().optional(),
  claimSummarySubtext: z.string().optional(),
  warrantySummarySubtext: z.string().optional(),
  returnSummarySubtext: z.string().optional(),
  hideOrderStatusUrl: z.boolean().optional(),
  hideRateYourExperienceWidget: z.boolean().optional(),
  hideLoginOrderNumberTooltip: z.boolean().optional(),
  collapseVariantThreshold: z.number().optional(),
  merchantDisplayName: z.string().optional(),
  useBetaPackagePickupModal: z.boolean().nullish(),
  socialLoginOptions: z.array(z.nativeEnum(SocialLoginOption)).nullish(),
});

export interface Portal {
  _id: string;
  /** @deprecated use brand kit instead */
  font_family: string;
  /** @deprecated use brand kit instead */
  title_font_family: string;
  /** @deprecated use brand kit instead */
  body_font_family: string;
  shippingFeeText: string;
  /** Maybe should be in brand kit but not deprecated right now */
  custom_css: string;
  new_custom_css: string;
  /** @deprecated use brand kit instead */
  font_weight: 100 | 300 | 400 | 500 | 700 | 900;
  /** @deprecated use brand kit instead */
  button_color: string;
  /** @deprecated use brand kit instead */
  accent_color: string;
  /** @deprecated use brand kit instead */
  accent_background_color: string;
  /** @deprecated use brand kit instead */
  home_text_color: string;
  enable_alert: boolean;
  notification: string;
  returnButtonText: string;
  claimButtonText: string;
  notExchangeButtonText?: string;
  exchangeButtonText?: string;
  standard_exchange_text?: string;
  hideDiscountCodes: boolean;
  hideSuggestedProducts: boolean;
  instant_exchange_text: string;
  custom_payment_placeholder_color?: string;
  privacy_link: string;
  /** Maybe should be in brand kit but not deprecated right now */
  background_url: string | null;
  /** @deprecated use brand kit instead */
  favicon_url: string | null;
  /** @deprecated use brand kit instead  */
  logo_url: string | null;
  /** Maybe should be in brand kit but not deprecated right now */
  colors: string[];
  /** Maybe should be in brand kit but not deprecated right now */
  injection_text_color: string;
  header?: string;
  footer?: string;
  green_return_text?: string;
  custom_confirmation_text?: string;
  green_return_confirmation_text?: string;
  green_return_confirmation_description?: string;
  exchangeOptionText?: string;
  storeCreditOptionText?: string;
  refundOptionText?: string;
  claimReviewHeader?: string;
  claimShippingLineItemText?: string;
  domain?: string;
  pathPrefix: string;
  cantFindOrderText?: string;
  claimSummarySubtext?: string;
  warrantySummarySubtext?: string;
  returnSummarySubtext?: string;
  hideOrderStatusUrl?: boolean;
  hideRateYourExperienceWidget?: boolean;
  hideLoginOrderNumberTooltip?: boolean;
  collapseVariantThreshold?: number;
  merchantDisplayName?: string;
  useBetaPackagePickupModal?: boolean;
  socialLoginOptions?: SocialLoginOption[];
}

export const IndustryCategoryZodSchema = z.object({
  redoAssignedCategories: z.array(z.string()).nullish(),
  autoAssignedCategories: z.array(z.string()).nullish(),
  allCategoriesIncludingTransitiveCategories: z.array(z.string()).nullish(),
});
export interface IndustryCategory {
  redoAssignedCategories?: string[];
  autoAssignedCategories?: string[];
  allCategoriesIncludingTransitiveCategories?: string[];
}

export const TeamZodSchema = z.object({
  address: AddressZodSchema.nullish(),
  storefrontAccessToken: z.string().nullish(),
  accessScope: z.array(z.string()),
  billingFailure: z.enum(["allow", "restrict"]),
  billingMethod: z.string(),
  customer_email: z.string(),
  publicAddress: AddressZodSchema.nullish(),
  damagedAddress: AddressZodSchema.nullish(),
  automation: AutomationZodSchema,
  failed_payouts: z.number().nullish(),
  shutdown_date: z.date().nullish(),
  _id: zExt.objectId(),
  email: z.string(),
  adminName: z.string(),
  adminPhone: z.string().nullish(),
  industryCategory: IndustryCategoryZodSchema.nullish(),
  intercomId: z.string(),
  lastOrderSync: z.date(),
  orderBackfillWatermark: z.date(),
  orderBackfillMin: z.date(),
  merchantPricePerOrder: z.string().nullish(),
  name: z.string(),
  onboarding: z.any(),
  notification_email: z.string(),
  notify_on_return_created: z.boolean(),
  notify_on_delivery: z.boolean(),
  notify_on_needs_review: z.boolean(),
  claim_notification_email: z.string(),
  notify_on_claim_created: z.boolean(),
  notify_on_claim_delivery: z.boolean(),
  notify_on_claim_needs_review: z.boolean(),
  portal: PortalZodSchema,
  pricePerOrder: z.string().nullish(),
  receipt_email: z.string(),
  returnLocationId: z.string().nullish(),
  settings: z.any(), //TODO: Will some brave soul type this out?
  storeUrl: z.string(),
  stripe: z.any().nullish(), //TODO: Type this out
  users: z.array(GetUserZodSchema),
  widget_slug: z.string(),
  storePassword: z.string().nullish(),
  _shopify: z.record(z.any()),
  createdAt: z.date(),
});

export interface Team {
  address?: Address;
  storefrontAccessToken?: string | null;
  accessScope: string[];
  billingFailure: "allow" | "restrict";
  billingMethod: string;
  customer_email: string;
  publicAddress?: Address;
  damagedAddress?: Address;
  customInStoreAddresses?: Address[];
  automation: Automation;
  failed_payouts: number;
  shutdown_date: string;
  _id: string;
  email: string;
  adminName: string;
  adminPhone: string;
  industryCategory?: IndustryCategory;
  intercomId: string;
  lastOrderSync: string;
  orderBackfillWatermark: string;
  orderBackfillMin: string;
  commentsoldBackSyncOrders?: {
    stopAt: string;
    current: string;
    intervalSeconds: number;
  };
  commentsoldOrderSyncAt?: string;
  merchantPricePerOrder?: string;
  name: string;
  onboarding: Onboarding;
  notification_email: string;
  notify_on_return_created: boolean;
  notify_on_delivery: boolean;
  notify_on_needs_review: boolean;
  claim_notification_email: string;
  warranty_notification_email: string;
  notify_on_claim_created: boolean;
  notify_on_claim_delivery: boolean;
  notify_on_claim_needs_review: boolean;
  notify_on_warranty_created: boolean;
  notify_on_warranty_needs_review: boolean;
  portal: Portal;
  pricePerOrder: string;
  receipt_email: string;
  returnLocationId?: string;
  settings: Settings;
  storeUrl: string;
  stripe?: StripeData;
  users: TeamUser[];
  widget_slug: string;
  storePassword: string;
  _shopify: Record<string, any>;
  createdAt: string;

  save(): Promise<void>;
}

export interface DateRange {
  start: string | null;
  end: string | null;
}

export enum DiscountDistributionMethod {
  FAMILY = "DistributeAcrossFamily",
  ORDER = "DistributeAcrossOrder",
  LINE_ITEM = "LineItem",
}

export enum OutOfStockAdvanced {
  CANCEL = "cancel",
  CONTINUE = "continue",
  OVERSELL = "oversell",
}

export enum NonZeroValueExchangeStrategy {
  NONE = "none",
  APPLY_DISCOUNT = "apply_discount",
  MARK_AS_PAID = "mark_as_paid",
}

export interface PagesData {
  pageId: string;
  pageName: string;
  pageAccessToken: string;
  instagramBusinessAccountId?: string;
  instagramUsername?: string;
}

export interface QuickLink {
  title: string;
  url: string;
}

export enum AccountTabSectionType {
  PRODUCT_RECOMMENDATIONS = "product_recommendations",
  VIEWED_PRODUCTS = "viewed_products",
  RECENT_ORDERS = "recent_orders",
  WISH_LIST = "wish_list",
}

export enum CloseActionMethod {
  NEXT = "next",
  TABLE = "table",
  STAY = "stay",
}

export enum SendType {
  SEND = "send",
  CLOSE = "close",
  IN_PROGRESS = "in_progress",
}

export interface AccountTabSection {
  type: AccountTabSectionType;
  header: string;
  enabled: boolean;
}

export const defaultWishListAccountTabHeader = "Wish list";
export const defaultAccountTabSections: AccountTabSection[] = [
  {
    type: AccountTabSectionType.RECENT_ORDERS,
    header: "Recent orders",
    enabled: true,
  },
  {
    type: AccountTabSectionType.WISH_LIST,
    header: defaultWishListAccountTabHeader,
    enabled: true,
  },
  {
    type: AccountTabSectionType.VIEWED_PRODUCTS,
    header: "Jump back in",
    enabled: true,
  },
  {
    type: AccountTabSectionType.PRODUCT_RECOMMENDATIONS,
    header: "Find new favorites",
    enabled: true,
  },
];

export interface EmailInfo {
  name: string;
  email: string;
  integrationKind: string;
}

export interface SupportAiToolSettings {
  cancelOrder?: { cancelOrderTimeWindowAfterPlacingMinutes?: number };
  updateOrderAddress?: {
    updateOrderAddressTimeWindowAfterPlacingMinutes?: number;
  };
}

export interface SupportAiSettings {
  enabled: boolean;
  respondToInternalEmails?: boolean;
  copilotEnabled: boolean;
  autoGenerateResponses?: boolean;
  autopilotEnabled?: boolean;
  aiAutopilotTicketVolume?: number;
  automateLiveChat?: boolean;
  autopilotToolSettings?: SupportAiToolSettings;
  enabledPlatforms?: {
    // these are the only platforms supported on autopilot as on 03/14/25
    email?: boolean;
    facebook?: boolean;
    instagram?: boolean;
    sms?: boolean;
    attentive?: boolean;
    postscript?: boolean;
  };
  trialMessagesRemaining?: number;
  hasTriedAi: boolean;
}

export enum ReviewDiscountEvent {
  REVIEW = "review",
  MEDIA = "media",
}

export interface PackageProtectionSettings {
  enabled: boolean;
  coverage?: boolean;
  customerClaimsEnabled?: boolean;
  packageProtectionPlusEnabled?: boolean;
  packageProtectionPlusPercentage?: number;
  packageProtectionPlusAllClaimsCovered?: boolean;
  minPrice: number;
  maxPrice: number;
  percentage: number;
  splitProducts: boolean;
  pricingRuleSet?: PricingRuleSet[];
  maxCoverageEligibleCartTotal?: number;
}

export interface FinalSaleReturnsSettings {
  enabled: boolean;
  validCollections: string[];
  validProductTags: string[];
  minPrice: number;
  maxPrice: number;
  percentage: number;
  allRedoPurchasesAreFinalSale: boolean;
  pricingRuleSet?: PricingRuleSet[];
}

interface ReturnItemDispositionSettings {
  enabled: boolean;
  gradeOptions: string[];
  outcomeOptions: string[];
}

export enum ReturnMethod {
  CUSTOMER_SHIPS = "customer_ships",
  CUSTOMER_IN_STORE = "customer_in_store",
  PACKAGE_PICKUP = "package_pickup",
}

export type InternationalReturnPricing = {
  countries: string[];
  multiplier: number;
}[];

export enum ReviewPrioritizationOption {
  HighestPrice = "Highest price",
  LowestReview = "Lowest review",
  HighestReview = "Highest review",
  Random = "Random",
}

export type InstantExchangeHoldKind = "none" | "one_dollar" | "full_cost";

export type DkimStatus = "pending" | "verified" | "failed" | "not_started";

type ContactFormFieldSettings = {
  /** Whether the field is shown in the contact form */
  show: boolean;
  /** Whether the customer is required to fill out the field in order to submit the form */
  required: boolean;
  /** Placeholder text shown before the customer provides a value for the field */
  placeholder: string;
};

export type ContactFormSettings = {
  enabled: boolean;
  title: string;

  name: ContactFormFieldSettings;
  email: Omit<ContactFormFieldSettings, "show" | "required">;
  subject: ContactFormFieldSettings & { options: string[] };
  phone: ContactFormFieldSettings;
  comment: Omit<ContactFormFieldSettings, "show" | "required">;

  allowFileUpload: boolean;

  replyEmail: EmailInfo | undefined;
  successMessage: string;
};

export const contactFormSettingsDefault: ContactFormSettings = {
  enabled: false,
  title: "Contact Us",
  name: { show: true, required: false, placeholder: "Enter your name" },
  email: { placeholder: "Enter your email" },
  subject: {
    show: true,
    required: false,
    placeholder: "Select a subject",
    options: ["General Inquiry", "Order Inquiry", "Feedback", "Other"],
  },
  phone: {
    show: true,
    required: false,
    placeholder: "Enter your phone number",
  },
  comment: {
    placeholder:
      "Leave a comment to help us better know what you're contacting us about.",
  },
  allowFileUpload: false,
  replyEmail: undefined,
  successMessage:
    "Thanks for contacting us. We'll get back to you as soon as possible.",
};

interface AiBilling {
  freeTrialEndDate?: Date | null;
  atShopifyCap?: boolean;
  ticketsUsed?: number;
  billingAccepted?: boolean;
  pricePerMonth?: number;
  aiResolutionPriceInCents?: number;
  ticketsCovered?: number;
  lastDayActive?: string;
}
export interface BaseSupportBilling {
  pricePerMonthInCents?: number | null;
  subscriptionActive: boolean;
  acceptedBillingAt?: Date | null;
  canceledBillingAt?: Date | null;
  freeTrialEndDate?: Date | null;
}

export interface MarketingSmsBilling {
  enabled: boolean;
  startDate?: string;
  period: BillingPeriod;
  periodFeeInCents: number;
  freeSmsCreditsPerMonth: number;
  overageSmsPriceInThousandthCents: number;
  overageMmsPriceInThousandthCents: number;
  renewOnPeriodEnd: boolean;
}

export interface MarketingEmailBilling {
  enabled: boolean;
  startDate?: string;
  period: BillingPeriod;
  periodFeeInCents: number;
  freeEmailsPerMonth: number;
  pricePerThousandInCents: number;
  renewOnPeriodEnd: boolean;
}

interface MarketingSupportConnectionSettings {
  preferredEmail?: string;
}

export interface MarketingSettings {
  enabled: boolean;
  smsBilling?: MarketingSmsBilling;
  emailBilling?: MarketingEmailBilling;
  textsEnabled: boolean;
  bypassPhoneNumber: boolean;
  subscriptionEntryPoints: { login: boolean; saveForLater: boolean };
  campaigns?: { enabled?: boolean };
  smartSending?: { secondsBetweenSends: number; enabled: boolean };
  smsStoreName?: string;
  customerImports?: string[];
  emailConversions?: string[];
  supportConnection?: MarketingSupportConnectionSettings;
}

export interface SupportSettings {
  enabled: boolean;
  billing?: {
    textMessaging?: TextMessagingBilling;
    ai?: AiBilling;
    base?: BaseSupportBilling;
  };
  csat?: {
    enabled: boolean;
    surveySendTime?: string;
    surveyMethods: string[];
    allowRemarks?: boolean;
    headerImage?: string;
    surveyMessage?: string;
  };
  closeAction: CloseActionMethod;
  defaultSendAction?: SendType;
  useInProgressStatus?: boolean;
  keepAssigneeOnTicketReopen?: boolean;
  closeTicketsOnGmailArchive?: boolean;
  /** Controls the contact form merchants can embed on their website */
  contactForm?: ContactFormSettings;
  receiveEmail?: string;
  senderEmails?: SenderEmail[];
  emailSignature?: string;
  tags?: ConversationTag[];
  // TODO: remove
  bccEmails?: boolean;
  tagSpam?: boolean;
  ticketAssignment: "manual" | "balanced" | "round-robin";
  usersForAutoAssignment: string[];
  autoAssignOnResponse?: boolean;
  autoReassignOnResponse?: boolean;
  maxTicketsPerUser: string;
  metaPagesData?: {
    userId?: string;
    longLivedToken?: string;
    pagesData?: PagesData[];
  };
  excludedEmails?: string[];
  ai?: SupportAiSettings;
  voice?: TeamVoiceConfiguration;
  instagram?: {
    autoCloseMentions: boolean;
    autoCloseRepliedStories: boolean;
    commentsEnabled: boolean;
    autoCloseExternal?: boolean;
  };
  facebook?: { autoCloseExternal?: boolean; commentsEnabled: boolean };
  propagateDeletes?: boolean;
  faq?: IFaqTheme;
  autoMergeEnabled?: boolean;
  defaultEmail?: string;
  undoSend?: boolean;
}

export interface IndustryCategory {
  redoAssignedCategories?: string[];
  autoAssignedCategories?: string[];
  allCategoriesIncludingTransitiveCategories?: string[];
}

interface CheckoutButtonsSettings {
  addProductOnOptIn: boolean;
}

// Setting for creating multiple return labels
// we always create multiple labels for multiple return address
export enum ReturnLabelQuantity {
  PER_RETURN = "return",
  PER_ITEM = "item",
  PER_FULFILLMENT = "fulfillment",
}

export interface Settings {
  concierge: ConciergeSettings;
  checkoutOptimization: CheckoutOptimizationSettings;
  crossSellPopoutEnabled: boolean;
  commentsold: {
    sendDropShipperEmail?: boolean;
    useShopifyStorefront?: boolean;
    recProductExclusions?: {
      variantIds?: string[];
      parentIds?: string[];
      tags?: string[];
    };
  };
  products: {
    barcode?: {
      size: {
        height: { value: Decimal128Extended; unit: string };
        width: { value: Decimal128Extended; unit: string };
      };
      topDisplayOption: BARCODE_HEADER_FOOTER_OPTIONS;
      bottomDisplayOption: BARCODE_HEADER_FOOTER_OPTIONS;
    };
  };
  discountDistributionMethod: DiscountDistributionMethod;
  showPDFInvoiceButton: boolean;
  shopifySubscription?: ShopifySubscription[];
  shopifySubscriptionMetadata: {
    isAtShopifyBillingCap: boolean;
    pendingCapRaiseUrl?: string;
  };
  inventory: {
    followShopifyInventoryPolicy: boolean;
    minimum_stock: number;
    restock: boolean;
    restockDamaged: boolean;
    restockShopifyRefunds?: boolean;
    allowRestockBeforeProcess: boolean;
  };
  labelExpiration: {
    days: number;
    reminderEmails: boolean;
    expirationEmails: boolean;
    reminderEmailDays: number[];
    strategy: LabelExpirationStrategy;
    compensationMethodsToRemind: CompensationMethod[];
    shouldReopenReturnWhenLabelUsed: boolean;
    reopenReturnAfterDays: number;
  };
  chatFlow: Json;
  useShadowRootInCart?: boolean;
  cartShadowRootSelector?: string;
  checkboxFontSize?: number;
  checkboxSelector?: string;
  checkboxText?: string;
  claimFlow: Json;
  /** Hides products in the return portal so they can't be returned */
  portalExcludedProductTags?: string[];
  portalExcludedCollections?: string[];
  checkboxTextAfterRedo?: string;
  countries: string[];
  countriesPackageProtection: string[];
  coverage: Coverage;
  subscriptionEnabled: boolean; //Coverage and Subscription are exclusive. Either coverage is enabled or subscription is enabled but not both.
  coverageEnabled: boolean;
  coverageExcludeCollections: string[];
  coverageExcludeCustomerTags: string[];
  coverageExcludeSources: string[];
  coverageExcludeTags: string[];
  coverageExcludeProductTags: string[];
  coverageExcludeDiscountCodes: string[];
  coverageExcludeProperties: string[];
  coverageExcludeCentEnding: string[];
  createReturnBarcodes?: boolean;
  bulkReturnsEnabled?: boolean;

  loadAllAdvancedExchangeProducts?: boolean;
  packagePickupVariants?: PackagePickupVariant[];
  excludeCoverageIfAnyInvalidProduct?: boolean;
  discountCodePartialMatch?: boolean;
  createdAt: string;
  customerSupport: {
    wrongProduct: CustomerSupportSetting;
    damagedItem: CustomerSupportSetting;
  };
  enabled: boolean;
  exchanges: {
    enableNewExchangeProcessing: boolean;
    advancedExchanges: AdvancedExchanges;
    selection: ExchangesSelection;
    allowInstant: boolean;
    instantHoldAmount: InstantExchangeHoldKind;
    instantExpirationDays: number;
    notes: { enabled: boolean };
    suffix: string | null;
    shouldStripHash: boolean;
    orderName: {
      prefix: string;
      suffix: string;
      orderNamesByReturnType: {
        prefix: string;
        suffix: string;
        returnType: ReturnTypeEnum;
      }[];
      useShopifyGeneratedName: boolean;
    };
    outOfStockPolicy: RefundType;
    excessExchangeValue: RefundType;
    outOfStockAdvanced: OutOfStockAdvanced;
    excludeProductTags: string[];
    recreateExpiredDiscounts: boolean;
    blockDiscountsOnExchanges: boolean;
    shopSiteURL: string;
    shopSiteParams: string;
    shopSiteDomain: string;
    shopSitePath: string;
    enableOnHoldOrders: boolean;
    onHoldOrdersOnUpsellOnly?: boolean;
    differentPricedVariantExchanges: boolean;
    variantExchangeProductTags: string[];
    usePreDiscountPrice: boolean;
    instantExchangeOnly?: boolean;
    shopSiteOneTab?: boolean;
    shopSiteUseCartDiscounts?: boolean;
    reopenExpiredInstantUponShipment?: boolean;
    instantExchangeAutoRefundIfExpired: boolean;
    createLabelOnDraftOrderComplete: boolean;
    exchangeOptionsPreviewShowOriginalPrice?: boolean;
    nonZeroValueExchange?: boolean;
    nonZeroValueExchangeStrategy?: NonZeroValueExchangeStrategy;
    excludedItemProperties?: { name: string; value: string }[];
    defaultPosRestock?: boolean;
  };
  failed_payouts: number;
  hideCheckboxBranding: boolean;
  hideCheckboxPrice: boolean;
  hidePortalBranding: boolean;
  hideCheckbox: boolean;
  images: string[];
  locations: ShippingLocation[];
  returnInStoreEnabled?: boolean;
  inStoreDropOffPackingSlipEnabled?: boolean;
  merchantCoverage: Coverage;
  returnAdjustment: { positiveName: string; negativeName: string };
  paidModel: PaymentModel;
  policyType: string;
  productName?: string;
  protectionExcludeTags?: string[];
  receipt_email: string;
  redoAutoCheck: boolean;
  packageProtectionAutoCheck: boolean;
  returnFlow: Json;
  finalizeReturnFlow: Json;
  finalizeClaimFlow: Json;
  warrantyFlow: Json;
  returnItemDisposition: ReturnItemDispositionSettings;
  returnsUI?: { defaultExpanded?: boolean };
  returnLocationId: string;
  returnPortal: string;
  returnPortalHeaderText?: string;
  returnPortalMissingOrderHeaderText?: string;
  returnPortalButtonText?: string;
  returnPortalGiftButtonText?: string;
  shippingCarrierOverride: string[];
  shippingCarrierAccounts?: string[];
  shippingInsurance: ShippingInsurance;
  shippingServiceOverride: Map<string, string[]>;
  generateQrCodes: boolean;
  shopAgainAction: "gift_card" | "discount_code" | "rise" | "shopify_account";
  riseTrigger: { url: string } | null;
  sku?: string;
  sku2?: string;
  returnsSKU?: string;
  packageProtectionSKU?: string;
  combinedSKU?: string;
  uniqueVariantSKU: boolean;
  html?: string;
  updatedAt: string;
  upsert: { enabled: boolean; value: string; type: string };
  widget: { url: string };
  packingSlipEnabled?: boolean;
  deductLabelFromCredit?: boolean;
  deductLabelFromRefund?: boolean;
  cartToggleEnabled: boolean;
  cartToggleSelector?: string;
  cartClickListenerSelector?: string;
  toggleOnColor: string;
  toggleOffColor: string;
  toggleCircleColor: string;
  cartTogglePlacement?: "before" | "after" | "prepend" | "append";
  cartReplaceItems?: boolean;
  packingInstructions?: { [key in ReturnMethod]: string[] };
  exchangeGroups: ExchangeGroup[];
  shopifyExtension?: {
    exchangeBannerPositionBottom?: boolean;
    exchangeBannerInsertBeforeBody?: boolean;
    hideRedoProduct?: HideRedoConfig;
    customizationVisible?: boolean;
    customizeCartItem?: boolean;
    brandPrefix?: string;
    logo?: string;
    redoProductImage?: string;
    imageUpdated?: boolean;
    displayIcon?: boolean;
    iconColor?: string;
    iconBackgroundColor?: string;
    icon?: Icons;
    returnNameOverride?: string;
    packageProtectionNameOverride?: string;
    bothProductNameOverride?: string;
    checkoutExtensionEnabled?: boolean;
    attachmentStrategy?: AttachmentStrategy;
    toggleSubtextEnabled?: boolean;
    toggleTextOptions?: {
      returnToggle: ToggleFields;
      packageProtectionToggle: ToggleFields;
      bothProductToggle: ToggleFields;
    };
    infoIconLink?: string;
    customPdpCss?: string;
    customToggleCss?: string;
    forceIPAddressLocation?: boolean;
    coverageExcludePickup?: boolean;
    usingCartAndCheckoutToggle?: boolean;
    forceDoubleCheckoutToggle?: boolean;
    setCheckoutToggleBasedOnOptInCartAttribute?: boolean;
    checkoutUICustomizations?: CheckoutUICustomizationSettings;
    groupNearbyToggleElementsTogether?: boolean;
    termsAndConditions?: TermsAndConditions;
    clickStandardCheckoutButton?: ClickStandardCheckoutButton;
    widgetConfigs?: WidgetConfig[];
    checkoutButtonsSettings?: CheckoutButtonsSettings;
    redoVariantBaseName?: string;
  };
  returns?: {
    enabled: boolean;
    refundAllReturnedItems: boolean;
    dynamicPricing?: DynamicReturnPricing;
    settlementEnabled: boolean;
    settlementRefund?: number;
    multipleLabelsEnabled?: boolean;
    internationalPricing?: InternationalReturnPricing;
    finalSaleReturns?: FinalSaleReturnsSettings;
    allowMultiOrderReturns?: boolean;
    multiOrderReturnLimit?: number;
    disableRejectedItems?: boolean;
    allowReopenAfterProcessing?: boolean;
    hideGiftCardBranding?: boolean;
    oneShipmentPerItemEnabled?: boolean;
    returnLabelQuantity?: ReturnLabelQuantity;
    /** For green international returns. shopper will be told to buy their own label.*/
    buyYourOwnLabel?: boolean;
    byolDomestically?: boolean;
    returnToFulfillmentOrigin?: boolean;
    checkForTryNowTags?: boolean;
    shouldAutoProcessRedoReturnWhenShopifyReturnCloses?: boolean;
    enableCustomerPortal?: boolean;
    enableShopAppReturns?: boolean;
    /** Controls whether warehouse location is displayed on return details page */
    warehouseLocationEnabled?: boolean;
    useNewSubmitReturn?: boolean;
    newLogin?: boolean;
    dontChargeMerchantForDamagedOrWrongProductReturnLabels: boolean;
    removeMerchantPaidLabelUpcharge?: boolean;
    agentChatEnabled?: boolean;
    manualLabelInstructions?: string[];
  };
  /** allows merchant to change return address before return is approved */
  canEditReturnAddress?: boolean;
  packageProtection?: PackageProtectionSettings;
  support?: SupportSettings;
  customerAccounts?: CustomerAccountsSettings;
  customerWidget?: CustomerWidgetSettings;
  notifyCustomerDefault: boolean;
  merchantPaidEnabledDates?: DateRange[];
  showLabelExpirationDate: boolean;
  modalTextAdjustments: {
    text1?: string;
    title1?: string;
    text2?: string;
    title2?: string;
    text3?: string;
    title3?: string;
  };
  infoModal?: InfoModal;
  returnBarcodeWidth?: number;
  returnBarcodeHeight?: number;
  modalLogo?: string;
  labelPriceAdjustment?: number | null;
  labelPriceExcludeCoverage?: boolean;
  minimumLabelOunces?: number;
  defaultLineItemOunces?: number;
  toggleFontSize?: number;
  checkoutSelector?: string;
  exchangeBannerColor: string;
  exchangeBannerText: string;
  exchangeBannerFont: string;
  exchangeBannerFontColor: string;
  exchangeBannerShowDoneButton?: boolean;
  submitButtonSelector?: string;
  billingSettings: BillingSettings;
  bundleRulesList: BundleRules[];
  bundles: Bundle[];
  expandedBundlesIgnoreArchived?: boolean;
  pickupEnabled: boolean;
  shopifyPixelId?: string;
  splitPixelEnabled?: boolean;
  createShopifyReturns?: boolean;
  archiveShopifyOrdersAfterReturn?: boolean;
  instantRefundEnabled?: boolean;
  syncBlockingPrefixes?: string;
  blockFulfillmentOrderPrefixes?: string;
  publicapi?: {
    discountsAreFinalSales?: boolean;
    coverageExcludeLocalPickup?: boolean;
    couponedItemsDiscountThreshold?: number;
  };
  emailDomain?: EmailDomainSettings;
  orderTracking?: {
    enabled: boolean;
    enabledCommentsold?: boolean;
    commentsoldDarkLaunch?: boolean;
    createTrackers: boolean;
    stalledDays?: number;
    stalledDaysFulfillment?: number;
    excludeTrackMyOrderClicks?: boolean;
    orderDiscount?: { enabled: boolean; settings: DiscountSettings };
    showPDPDeliveryEstimate?: boolean;
    useRedoTrackingUrl: boolean;
    ads?: OrderTrackingAd[];
    upsellProducts?: UpsellProductSection;
    lineItemsSection?: LineItemsSectionSettings;
    internalNotifications?: {
      // TODO (Josh) replace with new advanced flow enums
      [TriggerType.ORDER_STALLED]?: boolean;
      [TriggerType.ORDER_DELAYED]?: boolean;
      [TriggerType.ORDER_ARRIVING_EARLY]?: boolean;
      [TriggerType.ORDER_RETURN_TO_SENDER]?: boolean;
      [TriggerType.ORDER_DELIVERY_ATTEMPTED]?: boolean;
      [TriggerType.ORDER_FAILURE]?: boolean;
      [TriggerType.ORDER_ERROR]?: boolean;
      [TriggerType.ORDER_CANCELLED]?: boolean;

      // [TriggerType.RETURN_STALLED]?: boolean;
      // [TriggerType.RETURN_DELAYED]?: boolean;
      // [TriggerType.RETURN_RETURN_TO_SENDER]?: boolean;
      // [TriggerType.RETURN_DELIVERY_ATTEMPTED]?: boolean;
      // [TriggerType.RETURN_FAILURE]?: boolean;
      // [TriggerType.RETURN_ERROR]?: boolean;
      // [TriggerType.RETURN_CANCELLED]?: boolean;
      email?: string;
    };
    billing?: {
      enabled: boolean;
      continueIfNotAccepted: boolean;
      rerouteBillingToMarketing?: boolean;
      pricePerOrder: number;
      freeOrdersPerMonth: number;
      freeUntilDateEnabled: boolean | null;
      freeUntilDate: string | null;
      texts?: {
        enabled: boolean;
        pricePerSms: number;
        pricePerMms: number;
      } | null;
      unbilledUsage?: {
        orderCount?: number | null;
        smsCount?: number | null;
        mmsCount?: number | null;
      } | null;
    } | null;
    mostRecentSync?: {
      fulfillmentsAttempted: number;
      fulfillmentsFailed: number;
      syncDate: string;
      ordersFullySynced: number;
      ordersNotFullySynced: number;
    };
    /** @deprecated */
    syncInProgress?: boolean;
    syncInProgressStartedAt?: Date;
    orderEditing?: { enabled: boolean };
    postPurchaseUpsell?: {
      enabled: boolean;
      billing?: OneClickUpsellBilling;
      type: UpsellProductSource;
      collection?: CollectionInfo;
      discountEnabled: boolean;
      discountType: DiscountValueType;
      discountAmount: string;
      createOrderOnUpsell?: boolean;
    };
    /** Overrides how long until the tracking link expires */
    authExpirationDays?: number;
  };
  returnTracking?: {
    enabled: boolean;
    ads?: OrderTrackingAd[];
    upsellProducts?: UpsellProductSection;
    lineItemsSection?: LineItemsSectionSettings;
    disableDefaultEmails: boolean;
  };
  reviews?: {
    enabled: boolean;
    response?: {
      moderationSettings?: ModerationSettings;
      incentivesSettings?: {
        enableDiscount?: boolean;
        discountEvents?: { type: ReviewDiscountEvent; amount: number }[];
        discountValueType: DiscountValueType;
        customDiscountCode?: string;
        discountExpirationDays?: number;
        minimumPurchaseAmount?: number;
      };
    };
  };
  outboundLabels: {
    packingSlipId?: string;
    address?: { autoCleanAddresses?: boolean };
    newOmsEnabled: boolean;
    buyLabelsWorkflowEnabled?: boolean;
    rateRefreshWorkflowId?: string;
    calculatedFulfillmentDays?: number;
    blockedCarrierAccounts?: string[];
    verifyAddresses?: boolean;
    notifyCustomerOnFulfillment?: boolean;
    table?: { defaultPageSize?: number };
    insuranceRatePercentage?: number;
    publishedAutomationGroups?: string[];
    useDiscountedOrderPriceForCustoms?: boolean;
    showScanNotifications?: boolean;
    showScanNoteNotifications?: boolean;
    customs?: CustomsSettings;
    learnedPresets?: {
      [LearnedPresetKind.CARRIER_SERVICE_LEVEL]: boolean;
      [LearnedPresetKind.PARCEL]: boolean;
      [LearnedPresetKind.WEIGHT]: boolean;
    };
    suggestMerge?: {
      enabled: boolean;
      mergeConditions?: {
        [FulfillmentGroupMergeMatcherKind.CUSTOMER]: boolean;
        [FulfillmentGroupMergeMatcherKind.SHIPPING_ADDRESS]: boolean;
        [FulfillmentGroupMergeMatcherKind.BILLING_ADDRESS]: boolean;
        [FulfillmentGroupMergeMatcherKind.RECIPIENT]: boolean;
      };
    };
    billing?: {
      test?: boolean;
      autoReload?: { threshold: number; amount: number; floor: number };
      fundingSources?: PaymentMethod[];
      activeFundingSources?: {
        primary?: FundingSourceReference;
        secondary?: FundingSourceReference;
      };
      disabledUntil?: Date;
    };
    internalNotifications?: {
      email?: string;
      notifyOnLowFunds?: boolean;
      notifyOnBalancePayment?: boolean;
    };
    autoMerge?: {
      enabled?: boolean;
      serviceLevelPriority: ServiceLevelPriority;
      refundOtherShippingOptions: boolean;
      addTags?: { enabled: boolean; tags: string[] };
      mergeConditions?: {
        [FulfillmentGroupMergeMatcherKind.PACKING_SLIP_UNPRINTED]?: boolean;
        [FulfillmentGroupMergeMatcherKind.PAYMENT_INFORMATION]?: boolean;
        [FulfillmentGroupMergeMatcherKind.BILLING_ADDRESS]?: boolean;
        [FulfillmentGroupMergeMatcherKind.RECIPIENT]?: boolean;
        [FulfillmentGroupMergeMatcherKind.CUSTOMER_NAME]?: boolean;
        [FulfillmentGroupMergeMatcherKind.OPEN_STATUS]?: boolean;
        [FulfillmentGroupMergeMatcherKind.OPEN_OR_PAYMENT_PENDING_STATUS]?: boolean;
      };
      exceptionConditions?: {
        orderTags?: { enabled: boolean; tags: string[] };
        packingSlipPrinted: { enabled: boolean };
        pickListPrinted: { enabled: boolean };
        salesChannel: { enabled: boolean; channels: string[] };
        productNameContainsX: { enabled: boolean; strings: string[] };
      };
    };
    documents?: {
      shippingLabel?: {
        format: PageFormat;
        includePackingSlip: boolean;
        includeLogo: boolean;
        includeSkus: boolean;
      };
      packingSlip?: {
        format: PageFormat;
        orientation: PageOrientation;
        includeFulfillmentBarcode: boolean;
        includeLogo: boolean;
        includeImage: boolean;
        includePrice: boolean;
        includeSku: boolean;
        orderBy: ItemOrderBy;
      };
      pickList?: {
        format: PageFormat;
        includeImage: boolean;
        orderBy: ItemOrderBy;
        includeBin?: boolean;
      };
    };
    workstations?: Workstation[];
    automationDelaySeconds?: number;
    binNamespace?: string;
    binKey?: string;
  };
  marketing?: MarketingSettings;
  variantExchangeTitle?: string;
  exchangeGroupsExchangeTitle?: string;
  sameItemTitle?: string;
  newItemTitle?: string;
  preProtectedOrdersEnabled: boolean;
  preProtectedOrderMinutes?: number;
  _id: string;
  resourceOverride?: LanguageResourceOverride;
  customerPortalVersion?: CustomerPortalVersion;
  enableNonShopifyClaims?: boolean;
  nonShopifyClaimsCollection?: { id: string; name: string };
  nonShopifyClaimsDisableEditQuantity?: boolean;
  convertRecyclingProducts?: boolean;
  recyclingProductId?: string;
  hideManualReviewStep?: boolean;
  hideSummaryCard?: boolean;
  hideGiftOption?: boolean;
  disableRefundLineItems: boolean;
  warranties: WarrantiesSettings;
  extendedWarranties?: ExtendedWarrantySettings;
  brandKit?: BrandKit;
  autoProcessGreenReturns: boolean;
  hideFeeInCompensationMethodModal?: boolean;
  email?: { utm?: { campaign?: { prefix?: string } } };
  createMultipleShopifyReturns?: boolean;
}

export function isPreProtectedOrdersEnabled({
  preProtectedOrdersEnabled,
  preProtectedOrderMinutes,
}: {
  preProtectedOrdersEnabled: boolean;
  preProtectedOrderMinutes: number | null | undefined;
}): boolean {
  return (
    isPreProtectedOrdersAllowed(preProtectedOrderMinutes) &&
    preProtectedOrdersEnabled
  );
}

/**
 * As of Oct 3, 2024, for pre-protected orders to be allowed to be enabled, someone at Redo must
 * have set the preProtectedOrderMinutes to a value greater than 0. Once preProtectedOrderMinutes
 * is set, the preProtectedOrdersEnabled setting can be used to toggle the feature on and off.
 */
export function isPreProtectedOrdersAllowed(
  preProtectedOrderMinutes: number | null | undefined,
): boolean {
  return !!preProtectedOrderMinutes && preProtectedOrderMinutes > 0;
}

export type UpsellProductSection = {
  source: UpsellProductSource;
  hoverSecondImage?: boolean;
  collectionId?: string;
  collectionName?: string;
  metafield?: { key: string; namespace: string; metaobjectKey?: string };
  metafieldKey?: string;
  text?: string;
  minimumStock?: number;
  productTags?: string[];
  productEstimateEnabled?: boolean;
  manuallySelectedProducts?: { productId: string; variantId: string }[];
  recommendedProductFilterId?: string;
  imageAspectRatio?: number;
};

export type LineItemsSectionSettings = { imageAspectRatio?: number };

export type FundingSourceReference =
  | { source: "outbound_labels"; id: string }
  | { source: "general"; priority: "primary" | "secondary" | "card" };

export interface OrderTrackingAd {
  imageUrl: string;
  url: string;
  altText: string;
  type: AdLinkType;
  showOnlyNonSubscribed: boolean;
  duplicate?: () => OrderTrackingAd;
}

export const orderTrackingAdEqual: Equal<OrderTrackingAd> =
  objectEqual<OrderTrackingAd>({
    imageUrl: stringEqual,
    url: stringEqual,
    altText: stringEqual,
    type: stringEqual,
    showOnlyNonSubscribed: booleanEqual,
    duplicate: optionalEqual(() => true),
  });

export interface HideRedoConfig {
  enabled: boolean;
  productSelector?: string;
  checkoutButtonSelector?: string;
  cartCountModifications?: CartCountModification[];
  cartPriceModifications?: CartCountModification[];
  cartDiscountModifications?: CartCountModification[];
}

export interface CartCountModification {
  selector: string;
  search: string;
  replace: string;
}

export interface ModerationSettings {
  autoPublish?: boolean;
  ratingLevel?: number;
  blockUnverifiedReviews?: boolean;
  blockMediaReviews?: boolean;
  blockProfanityReviews?: boolean;
  blockNegativeSentimentReviews?: boolean;
  publishAfterTime?: number;
  excludeReturnReviewers?: boolean;
  excludedCustomerTags?: string[];
  excludedProducts?: string[];
  reviewPrioritization?: ReviewPrioritizationOption;
}

export interface ToggleFields {
  titleText: string;
  positiveSubtext: string;
  negativeSubtext: string;
}

export interface CollectionInfo {
  id: string;
  name: string;
  kind: "smart" | "custom";
}

export type ExchangeGroupType =
  | "tag:tag"
  | "tag:collection"
  | "name:collection"
  | "collection:collection";

export type PriceDifferenceType = "ignore" | "apply";

export interface BaseExchangeGroup {
  type: ExchangeGroupType;
  groupName: string;
  priceDifference: PriceDifferenceType;
}

export interface TagForTagGroup extends BaseExchangeGroup {
  type: "tag:tag";
  sourceTags: string[];
  targetTags: string[];
}

export interface NameForCollectionGroup extends BaseExchangeGroup {
  type: "name:collection";
  sourceName: string;
  targetCollection: CollectionInfo;
}

export interface TagForCollectionGroup extends BaseExchangeGroup {
  type: "tag:collection";
  sourceTags: string[];
  targetCollection: CollectionInfo;
}

export interface CollectionForCollectionGroup extends BaseExchangeGroup {
  type: "collection:collection";
  sourceCollection: CollectionInfo;
  targetCollection: CollectionInfo;
}
export const CollectionInfoZodSchema = z.object({
  id: z.string(),
  name: z.string(),
  kind: z.enum(["smart", "custom"]),
});
export const ExchangeGroupTypeZodSchema = z.enum([
  "tag:tag",
  "tag:collection",
  "name:collection",
  "collection:collection",
]);
export const PriceDifferenceTypeZodSchema = z.enum(["ignore", "apply"]);
export const BaseExchangeGroupZodSchema = z.object({
  type: ExchangeGroupTypeZodSchema,
  groupName: z.string(),
  priceDifference: PriceDifferenceTypeZodSchema,
});

export const TagForTagGroupZodSchema = BaseExchangeGroupZodSchema.extend({
  type: z.literal("tag:tag"),
  sourceTags: z.array(z.string()),
  targetTags: z.array(z.string()),
});

export const NameForCollectionGroupZodSchema =
  BaseExchangeGroupZodSchema.extend({
    type: z.literal("name:collection"),
    sourceName: z.string(),
    targetCollection: CollectionInfoZodSchema,
  });

export const TagForCollectionGroupZodSchema = BaseExchangeGroupZodSchema.extend(
  {
    type: z.literal("tag:collection"),
    sourceTags: z.array(z.string()),
    targetCollection: CollectionInfoZodSchema,
  },
);

export const CollectionForCollectionGroupZodSchema =
  BaseExchangeGroupZodSchema.extend({
    type: z.literal("collection:collection"),
    sourceCollection: CollectionInfoZodSchema,
    targetCollection: CollectionInfoZodSchema,
  });

export const ExchangeGroupZodSchema = z.union([
  TagForTagGroupZodSchema,
  NameForCollectionGroupZodSchema,
  TagForCollectionGroupZodSchema,
  CollectionForCollectionGroupZodSchema,
]);

// If you update this, make sure to update the ExchangeGroupZodSchema
// Use ExchangeGroupZodSchema over this when possible
export type ExchangeGroup =
  | TagForTagGroup
  | NameForCollectionGroup
  | TagForCollectionGroup
  | CollectionForCollectionGroup;

export const exchangeGroupEqual: Equal<ExchangeGroup> =
  objectEqual<ExchangeGroup>({
    type: stringEqual,
    groupName: stringEqual,
    priceDifference: stringEqual,
    sourceName: optionalEqual(stringEqual),
    sourceTags: optionalEqual(arrayEqual(stringEqual)),
    targetTags: optionalEqual(arrayEqual(stringEqual)),
    sourceCollection: optionalEqual(
      objectEqual<CollectionInfo>({
        id: stringEqual,
        name: stringEqual,
        kind: stringEqual,
      }),
    ),
    targetCollection: optionalEqual(
      objectEqual<CollectionInfo>({
        id: stringEqual,
        name: stringEqual,
        kind: stringEqual,
      }),
    ),
  });

export enum AdvancedExchanges {
  ENABLED = "all",
  /** Limits advanced exchanges to items with value less than or equal to the exchange value */
  LESS_THAN_EQUAL = "less_than_equal",
  DISABLED = "variant_only",
}

export type Onboarding = any;

export interface Address {
  name: string;
  street1: string;
  street2?: string;
  city: string;
  zip: string;
  state?: string;
  country: string;
  country_name?: string;
  phone?: string;
  createdAt?: string;
  updatedAt?: string;
  latitude?: number;
  longitude?: number;
}

export interface CustomerSupportSetting {
  ask: boolean;
  ifYes: string;
  then: string;
}

export interface Provinces {
  country: CountryCode;
  provinceCodes: string[];
}

export interface ShippingLocation {
  flowTypes: string[];
  condition: LocationCondition | null;
  multipleChoiceQuestion: string | null;
  multipleChoiceQuestionAnswers: string[] | null;
  returnReasons: string[] | null;
  originalLocation: boolean;
  address?: Address;
  locationId: string;
  tags: string[] | null;
  collections: string[] | null;
  discounts: string[] | null;
  priceMatchType: PriceOption | null;
  price: string | null;
  skuMatchType: SkuOption | null;
  skus: string[] | null;
  salesChannels: string[] | null;
  countries: string[] | null;
  provinces: Provinces | null;
}

export interface TeamUser {
  roles: string[];
  _id: string;
  user: string | GetUser;
  email: string;
  createdAt: string;
  updatedAt: string;
}

export interface RenderedTeamUser {
  roles: string[];
  _id: string;
  user: GetUser;
  email: string;
  createdAt: string;
  updatedAt: string;
}

export type RenderedTeam = Omit<Team, "users"> & { users: RenderedTeamUser[] };

export type RefundType = "store_credit" | "refund";

export enum SenderEmailSetupStatus {
  CONNECTED = "connected",
  UNVERIFIED = "unverified",
}

export interface SenderEmail {
  email: string;
  emailSignature?: string;
  name: string;
  postmarkId: number;
  dkimHost: string;
  dkimText: string;
  returnPathDomain: string;
  returnPathValue: string;
  setupStatus: SenderEmailSetupStatus;
}

export interface BillingSettings {
  schedule: "bimonthly" | "weekly";
  dayOfWeek?: number;
  firstBillingDayOfMonth?: number;
}

interface CustomerAccountsSettings {
  enabled?: boolean;
  loyaltyEnabled?: boolean | undefined;
  webPixelEnabled?: boolean;
  // Manual DB setting, no UI. One off request for merchant with new Swym contract
  // for wishlists.
  hideLists?: boolean;
  adBlocks?: AdBlock[];
  saveForLaterPanel?: {
    enabled: boolean;
    title?: string | undefined;
    message?: string | undefined;
    numViewedProductsUntilPanelOpen?: number | undefined;
    anchorLocation?: SlideInPanelAnchorLocation | undefined;
  };
  nativeAccounts?: {
    overrideAccountLinks?: boolean | undefined;
    redirectFromAccountsPages?: boolean | undefined;
  };
}

export interface EmailSignUpSettings {
  incentivize: boolean;
  buttonLabel: string;
  signUpDiscountAmount: string;
}

interface ConciergeSettings {
  enabled: boolean;
  placeProductFormFromThemeExtension?: boolean;
  themeExtensionProductFormCustomCss?: string;
  guardrails?: { storeContactInformation?: string };
  general?: {
    name?: string;
    welcomeMessage?: string;
    toneOfVoice?: string;
    writingExamples?: string[];
    brandDescription?: string;
    customerDescription?: string;
    outputLength?: ResponseLength;
    ticketHandoffSettings?: {
      handoffType?: string;
      emailHandoffMessage?: string;
      isOnSupport?: boolean;
    };
  };
  context?: { productMetafieldKeys?: string[] };
  questionPromptCount?: number;
  skills?: {
    backInStockNotifications: { enabled: boolean };
    orderTracking: { enabled: boolean };
    cancelOrder: { enabled: boolean };
  };
  emailSignUp?: EmailSignUpSettings | undefined;
  proactiveChipEngagement?: boolean;
  showManualBestSellers?: boolean;
  overriddenBestSellers?: string[];
}

interface CheckoutOptimizationBilling {
  enabled: boolean;
  startDate?: Date;
  period: CheckoutOptimizationBillingPeriod;
  pricePerOrderInCents: number;
  renewOnPeriodEnd: boolean;
}

export interface CheckoutOptimizationSettings {
  enabled: boolean;
  fulfillmentCutoffSchedule?: FulfillmentCutoffSchedule;
  checkoutOptimizationBilling?: CheckoutOptimizationBilling;
}

interface CustomerWidgetSettings {
  general?: {
    chips?: CustomerWidgetChip[];
    enableInAppEmbed?: boolean;
    widgetQuickLinks?: QuickLink[];
    widgetQuickLinkFontSize?: string;
    selfServe?: {
      startReturnEnabled: boolean;
      startClaimEnabled: boolean;
      cancelOrderEnabled: boolean;
      trackPackageEnabled: boolean;
    };
    showCustomerWidgetInReturnApp?: boolean;
    homeHeadingText?: string;
    headingLogoUrl?: string;
    hideButton?: boolean;
    hideWidgetAfterHours?: boolean;
    widgetFontFamily?: string;
    widgetMessageBubbleFontSize?: string;
    widgetHeaderFontSize?: string;
    widgetButtonFontSize?: string;
    chatWithUsButtonText?: string;
    widgetColor?: string;
    widgetPosition?: "left" | "right";
    xAdjustment?: string;
    yAdjustment?: string;
    bubbleButtonText?: string;
    widgetHoursEnabled?: boolean;
    widgetHoursTimezone?: string;
    widgetHours?: {
      sunday?: {
        enabled?: boolean | null;
        timeBlocks?: { start?: string | null; end?: string | null }[];
      };
      monday?: {
        enabled?: boolean | null;
        timeBlocks?: { start?: string | null; end?: string | null }[];
      };
      tuesday?: {
        enabled?: boolean | null;
        timeBlocks?: { start?: string | null; end?: string | null }[];
      };
      wednesday?: {
        enabled?: boolean | null;
        timeBlocks?: { start?: string | null; end?: string | null }[];
      };
      thursday?: {
        enabled?: boolean | null;
        timeBlocks?: { start?: string | null; end?: string | null }[];
      };
      friday?: {
        enabled?: boolean | null;
        timeBlocks?: { start?: string | null; end?: string | null }[];
      };
      saturday?: {
        enabled?: boolean | null;
        timeBlocks?: { start?: string | null; end?: string | null }[];
      };
    };
  };
  support?: {
    chatExpirationMinutes?: number;
    chatExpirationMessage?: string;
    unresponsiveConversionMinutes?: number;
    unresponsiveConversionSeconds?: number;
    unresponsiveConversionEnabled?: boolean;
    unresponsiveConversionEmail?: EmailInfo;
    hideSupportWidgetFeatures?: boolean;
    messagingHeadingText?: string;
  };
  customerAccounts?: {
    registerHeader?: string;
    registerSubtext?: string;
    accountTabSections?: AccountTabSection[];
  };
}

interface DayHours {
  enabled?: boolean | undefined;
  timeBlocks?: { start?: string | undefined; end?: string | undefined }[];
}

export interface WidgetHours {
  sunday?: DayHours | undefined;
  monday?: DayHours | undefined;
  tuesday?: DayHours | undefined;
  wednesday?: DayHours | undefined;
  thursday?: DayHours | undefined;
  friday?: DayHours | undefined;
  saturday?: DayHours | undefined;
}

export interface TeamInfo {
  id: string;
  widgetSlug: string;
  returnsEnabled: boolean;
  customerClaimsEnabled: boolean;
  orderTrackingEnabled: boolean;
  marketingEnabled: boolean;
  marketingSubscribeFromLogin: boolean;
  customerAccountsEnabled: boolean;
  conciergeEnabled: boolean;
  conciergeLiveChat: boolean;
  conciergeSettings: {
    general: { assistantName: string | undefined; welcomeMessage: string };
    emailSignUp?: Omit<EmailSignUpSettings, "signUpDiscountAmount"> | undefined;
    proactiveChipEngagement: boolean;
    ticketHandoffSettings?: {
      handoffType?: string;
      emailHandoffMessage?: string;
      forwardToExternalEmail?: boolean;
    };
  };
  chips: CustomerWidgetChip[];
  accountTab: {
    registerHeader: string;
    registerSubtext: string;
    sections: AccountTabSection[];
  };
  overrideAccountLinks: boolean;
  redirectFromAccountsPages: boolean;
  hideButton: boolean;
  multipassAvailable: boolean;
  supportFeaturesEnabled: boolean;
  url: string;
  chatHeadingLogoUrl: string;
  storeUrl: string;
  customerPortalDomain: string;
  customerPortalPathPrefix: string;
  storefrontAccessToken: string;
  customerPortalButtonText?: string;
  returnButtonText: string;
  claimButtonText: string;
  messagingHeadingText: string;
  homeHeadingText: string;
  contactFormSettings?: ContactFormSettings;
  name: string;
  chatFlow: Json;
  widgetQuickLinks?: QuickLink[];
  widgetQuickLinkFontSize?: string;
  csat: { enabled: boolean; surveyMethods: string[]; allowRemarks: boolean };
  selfServe: {
    startReturnEnabled: boolean;
    startClaimEnabled: boolean;
    cancelOrderEnabled: boolean;
    trackPackageEnabled: boolean;
  };
  hideWidgetAfterHours?: boolean;
  widgetPrimaryColor?: string;
  widgetFontFamily?: string;
  widgetMessageBubbleFontSize?: string;
  widgetHeaderFontSize?: string;
  widgetButtonFontSize?: string;
  chatWithUsButtonText?: string;
  widgetPosition?: "left" | "right";
  xAdjustment?: string;
  yAdjustment?: string;
  bubbleButtonText?: string;
  widgetHoursTimezone?: string;
  widgetHoursEnabled?: boolean;
  widgetHours?: WidgetHours | undefined;
}

export interface Workstation {
  _id?: string;
  name: string;
  overridePackingSlipTemplateId?: string;
  selectedPrinters: {
    format: PageFormat;
    printerId: number;
    printerName: string;
    printerOptions?: PrinterOptions;
  }[];
}

export class OrderTrackingSettings {
  constructor(private readonly teamSettings: Team["settings"]) {}

  withinUsageCap() {
    if (!this.teamSettings.orderTracking?.billing?.enabled) {
      return true;
    }
    const pricingDetails = getUsagePlanPricing(
      this.teamSettings.shopifySubscription?.[0],
    );
    const balanceUsed = Number(pricingDetails?.balanceUsed?.amount ?? 0);
    const cappedAmount = Number(pricingDetails?.cappedAmount?.amount ?? 0);
    return balanceUsed < cappedAmount;
  }

  servicesActive() {
    if (!this.teamSettings?.orderTracking?.enabled) {
      return false;
    }

    if (this.teamSettings.orderTracking.billing?.rerouteBillingToMarketing) {
      return true;
    }

    const usagePricing = getUsagePlanPricing(
      this.teamSettings.shopifySubscription?.[0],
    );

    return (
      (usagePricing && this.withinUsageCap()) ||
      this.teamSettings?.orderTracking?.billing?.continueIfNotAccepted
    );
  }

  canSendTexts() {
    return (
      this.servicesActive() &&
      (this.teamSettings?.orderTracking?.billing?.texts?.enabled ||
        !this.teamSettings?.orderTracking?.billing?.enabled)
    );
  }
}

export const teamCanUseAi = (team?: Team) => {
  const ai = team?.settings?.support?.ai;
  const billing = team?.settings?.support?.billing?.ai;
  if (ai && billing) {
    const billingConfirmed =
      team.settings.support?.billing?.ai?.billingAccepted;
    const atShopifyCap = billing.atShopifyCap;
    const lastDayActive = billing.lastDayActive;
    const lastDayActiveIsInFuture = lastDayActive
      ? new Date(lastDayActive) > new Date()
      : false;

    return (
      ai.enabled &&
      (billingConfirmed || lastDayActiveIsInFuture) &&
      !atShopifyCap
    );
  }
  return false;
};

export const allowSupportAiCopilot = (team?: Team): boolean => {
  const allowed = teamCanUseAi(team);
  return (allowed && team?.settings?.support?.ai?.copilotEnabled) ?? false;
};

export const shouldAutoGenerateAiResponses = (team?: Team): boolean => {
  const allowed = teamCanUseAi(team);
  return (
    (allowed && team?.settings?.support?.ai?.autoGenerateResponses) ?? false
  );
};

export const allowSupportAutopilot = (team?: Team): boolean => {
  const allowed = teamCanUseAi(team);
  return (allowed && team?.settings?.support?.ai?.autopilotEnabled) ?? false;
};

export function isSupportFeaturesEnabled({
  isSupportEnabled,
  hideSupportWidgetFeatures,
}: {
  isSupportEnabled: boolean;
  hideSupportWidgetFeatures: boolean;
}): boolean {
  return isSupportEnabled && !hideSupportWidgetFeatures;
}

export function isConciergeLiveChatEnabled({
  isConciergeEnabled,
  automateLiveChat,
}: {
  isConciergeEnabled: boolean;
  automateLiveChat: boolean;
}): boolean {
  return isConciergeEnabled && automateLiveChat;
}

export function getChatHeadingLogoUrl({
  widgetHeadingLogoUrl,
  brandKitFaviconUrl,
  portalFaviconUrl,
}: {
  widgetHeadingLogoUrl: string | undefined;
  brandKitFaviconUrl: string | undefined;
  portalFaviconUrl: string | undefined;
}): string {
  return widgetHeadingLogoUrl || brandKitFaviconUrl || portalFaviconUrl || "";
}

export function isConciergeChatWithTeamMemberEnabled(
  supportFeaturesEnabled: boolean,
  isWithinHours: boolean,
): boolean {
  return supportFeaturesEnabled && isWithinHours;
}

const TRANSACTIONAL_EMAIL_DOMAIN = "notify.getredomail.com";
const TRANSACTIONAL_EMAIL_CONFIGURATION_SET =
  "notify_getredomail_com-txnl-conf-set";
const MARKETING_EMAIL_DOMAIN = "shared.getredomail.com";
const MARKETING_EMAIL_CONFIGURATION_SET =
  "shared_getredomail_com-mktg-conf-set";

export function getFromEmailConfigForSes(team: Team, isTransactional: boolean) {
  const emailDomainSettings = team?.settings?.emailDomain;
  if (isVerifiedEmailDomain(emailDomainSettings)) {
    return {
      configurationSet: isTransactional
        ? emailDomainSettings.configurationSets?.transactional
        : emailDomainSettings.configurationSets?.marketing,
      name: emailDomainSettings.fromName || team.name || "",
      email: `${emailDomainSettings.fromEmail || "no-reply"}@${emailDomainSettings.domain}`,
    };
  } else if (emailDomainSettings && emailDomainSettings.redoMailEnabled) {
    const emailDomain = isTransactional
      ? TRANSACTIONAL_EMAIL_DOMAIN
      : MARKETING_EMAIL_DOMAIN;
    return {
      configurationSet: isTransactional
        ? TRANSACTIONAL_EMAIL_CONFIGURATION_SET
        : MARKETING_EMAIL_CONFIGURATION_SET,
      name: team.name ?? "Redo",
      email: `no-reply@${emailDomain}`,
    };
  } else {
    return {
      configurationSet: undefined,
      name: team.name ?? "Redo",
      email: "no-reply@getredo.com",
    };
  }
}
